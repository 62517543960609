import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import HeaderSection from "./HeaderSection";
import Headerartistique from "./Headerartistique";

import PresentationSection from "./PresentationSection";
import MaterialSection from "./MaterialSection";
import ExperienceSection from "./ExperienceSection";
import Podcast from "./Podcast";  // Importation du composant SoundExample
import MediaSection from "./MediaSection";
import MediaSectionart from "./MediaSectionart";

import Footer from "./Footer"; // Importation du Footer
import "./index.css"; // Importation du CSS global

// Page Technicien
function Technicien() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <HeaderSection scrollToSection={scrollToSection} />
      <PresentationSection />
      <MaterialSection />
      <ExperienceSection />
      <MediaSection />
    </div>
  );
}

// Page Artistique
function Artistique() {
  return (
    <div className="App">
      <Headerartistique/>
      <Podcast /> {/* Composant SoundExample affiché ici aussi */}
      <Footer />
      <MediaSectionart />

    </div>
  );
}

// App principale
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/technicien" element={<Technicien />} />
        <Route path="/artistique" element={<Artistique />} />
      </Routes>
    </Router>
  );
}

export default App;
