import React from 'react';
import { Link } from 'react-router-dom';
import './css/header.css';
import './css/typography.css';

const ArtisticHeader = () => {
  return (
        <div className="text-left">
          {/* Le titre devient un lien vers la page Home */}
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h1 className="header-title">Valentin Leverrier</h1>
          </Link>
          <div className="separator" />

        </div>
  );
};

export default ArtisticHeader;
