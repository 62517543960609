import React, { useState, useRef } from 'react';
import './css/MediaSection.css';
import image1 from './image/media/image1.webp';
import image2 from './image/media/image2.webp';
import image3 from './image/media/image3.webp';
import image4 from './image/media/moi1.webp';
import image5 from './image/media/studio.webp';
import image6 from './image/media/plage.webp';
import image7 from './image/media/studio3.webp';
import image8 from './image/media/montagne.webp';
import image9 from './image/media/barrios.webp';
import image10 from './image/media/jazz.webp';
import image11 from './image/media/jazz2.webp';
import image12 from './image/media/studio5.webp';


const MediaSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [selectedLink, setSelectedLink] = useState('');
  const mediaRef = useRef(null);

  const handleImageClick = (image, text, link) => {
    setSelectedImage(image);
    setSelectedText(text);
    setSelectedLink(link);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setSelectedText('');
    setSelectedLink('');
  };

  return (
    <div className="scroll-section" id="media" ref={mediaRef}>
      <h1 className="section-title" data-aos="fade-up">Gallerie</h1>
      <div className="media-gallery">
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image1)}>
          <img src={image1} alt="Media 1" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image2)}>
          <img src={image2} alt="Media 2" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image3)}>
          <img src={image3} alt="Media 3" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image4)}>
          <img src={image4} alt="Media 4" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image5)}>
          <img src={image5} alt="Media 5" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image6)}>
          <img src={image6} alt="Media 6" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image7)}>
          <img src={image7} alt="Media 7" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image8)}>
          <img src={image8} alt="Media 8" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image9)}>
          <img src={image9} alt="Media 9" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image10)}>
          <img src={image10} alt="Media 10" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image11)}>
          <img src={image11} alt="Media 11" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image12, '© ogata_photo', 'https://www.instagram.com/ogata_photo/')}>
          <img src={image12} alt="Media 12" />
        </div>
        {/* Add other images here */}
      </div>
      {selectedImage && (
        <div className="popup media-popup" onClick={closePopup}>
          <div className="popup-content">
            <img src={selectedImage} alt="Selected" />
            <div className="popup-text">
              {selectedLink ? (
                <a
  href={selectedLink}
  target="_blank"
  rel="noopener noreferrer"
  className="custom-link"
>
  {selectedText}
</a>
              ) : (
                selectedText
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaSection;