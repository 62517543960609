import React, { useState, useRef, useEffect } from 'react';
import './css/SoundExample.css';
import './css/typography.css';
import './css/layout.css';
import { FaPlay, FaPause, FaVolumeUp } from 'react-icons/fa';

import majesteImage from './image/image-son/majeste.png';
import résistanceImage from './image/image-son/Résistance.png';
import voyageurImage from './image/image-son/Voyageur.png';
import transmetteurImage from './image/image-son/Transmetteur.png';
import passionImage from './image/image-son/passion2.png';
import artisantImage from './image/image-son/artisant.png';
import libanaiseImage from './image/image-son/Yusra.png';
import amoureuxImage from './image/image-son/amoureux.png';
import transhumanceImage from './image/image-son/transhumance.png';
import cairnImage from './image/image-son/cairn.png';
import feuImage from './image/image-son/feu.png';
import au26Image from './image/image-son/au26.png';

import audioFileforet from './music/Sa_majesté_la_forêt.mp3';
import audioFilerésistance from './music/Résistance.mp3';
import audioFilevoyageur from './music/Voyageur.mp3';
import audioFiletransmetteur from './music/Transmetteur.mp3';
import audioFilepassion from './music/Passion.mp3';
import audioFileartisant from './music/artisant.mp3';
import audioFilelibanaise from './music/libanaise.mp3';
import audioFileamoureux from './music/amoureux.mp3';
import audioFiletranshumance from './music/Transhumances.mp3';
import audioFilefeu from './music/Feu.mp3';
import audioFilecairn from './music/cairn.mp3';
import audioFileau26 from './music/au26.mp3';

const SoundExample = () => {
  const [playingIndex, setPlayingIndex] = useState(null);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const audioRef = useRef(null);
  const volumeHideTimeoutRef = useRef(null);

  // Association des tags popup aux descriptions
  const popupDescriptions = {
    "@atelierlesbuissoniers": {
      title: "Les Buissoniers",
      content: "La Buissonière est un accueil de jour pour personnes atteintes d’Alzheimer. J’ai interviewé certains d’entre eux afin de choisir des profils et les présenter à une classe de collègiens. Les adolescents ont enregistré les sons servant à la composition finale et les voix, les souvenirs des “seigneurs” comme ils les appellent. L’idée étant d’évoquer ou illustrer certains passages de leur vie."
    }
  };

  const soundExamples = [
    { title: 'Sa majesté la forêt', image: majesteImage, audioPath: audioFileforet, text: 'Description sur la majesté de la forêt.' },
    { title: 'La résistante', image: résistanceImage, audioPath: audioFilerésistance, text: 'Une histoire de force et de résilience. @atelierlesbuissoniers' },
    { title: 'Le voyageur', image: voyageurImage, audioPath: audioFilevoyageur, text: 'Un voyageur explorant le monde.' },
    { title: 'Le transmetteur', image: transmetteurImage, audioPath: audioFiletransmetteur, text: 'Celui qui partage le savoir.' },
    { title: 'La passionnée', image: passionImage, audioPath: audioFilepassion, text: 'Une personne animée par sa passion.' },
    { title: 'L’artisan', image: artisantImage, audioPath: audioFileartisant, text: 'Un maître du savoir-faire.' },
    { title: 'La Libanaise', image: libanaiseImage, audioPath: audioFilelibanaise, text: 'Une histoire venue du Liban.' },
    { title: 'L’amoureux', image: amoureuxImage, audioPath: audioFileamoureux, text: 'Une histoire d’amour et de passion.' },
    { title: 'Transhumance', image: transhumanceImage, audioPath: audioFiletranshumance, text: 'Le voyage des bergers et de leurs troupeaux.' },
    { title: 'Cairn', image: cairnImage, audioPath: audioFilecairn, text: 'Les pierres empilées, témoins du temps.' },
    { title: 'Feu', image: feuImage, audioPath: audioFilefeu, text: 'L’élément primal et vivant.' },
    { title: 'Au26', image: au26Image, audioPath: audioFileau26, text: 'Un lieu, une histoire, une mémoire.' }
  ];

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      clearTimeout(volumeHideTimeoutRef.current);
    };
  }, []);

  const toggleAudio = (index, audioPath) => {
    if (playingIndex === index) {
      audioRef.current.pause();
      setPlayingIndex(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(audioPath);
      audioRef.current.volume = volume;
      audioRef.current.play().catch(error => console.error("Audio playback failed:", error));
      audioRef.current.addEventListener('timeupdate', updateProgress);
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current.duration);
      });
      setPlayingIndex(index);
    }
  };

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
    setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
  };

  const handleSeek = (e) => {
    if (!audioRef.current || !audioRef.current.duration) return;
    const rect = e.currentTarget.getBoundingClientRect();
    let clientX;
    if (e.touches && e.touches.length > 0) {
      clientX = e.touches[0].clientX;
    } else {
      clientX = e.clientX;
    }
    const offsetX = clientX - rect.left;
    const newTime = (offsetX / rect.width) * audioRef.current.duration;
    if (isFinite(newTime)) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    setVolume(newVolume);
    clearTimeout(volumeHideTimeoutRef.current);
    startHideVolumeTimer();
  };

  const startHideVolumeTimer = () => {
    volumeHideTimeoutRef.current = setTimeout(() => {
      setShowVolumeControl(false);
    }, 3000);
  };

  const toggleVolumeBar = () => {
    const newState = !showVolumeControl;
    setShowVolumeControl(newState);
    if (newState) {
      clearTimeout(volumeHideTimeoutRef.current);
      startHideVolumeTimer();
    }
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  // Ouvre la popup avec le contenu donné
  const openPopup = (popupData) => {
    setPopupContent(popupData);
    setPopupVisible(true);
  };

  // Ferme la popup
  const closePopup = () => {
    setPopupVisible(false);
    setPopupContent("");
  };

  // Sépare le texte de description du tag popup pour les afficher sur des lignes différentes
  const renderDescriptionText = (text) => {
    const words = text.split(" ");
    let popupToken = null;
    const textWithoutPopup = words.filter(word => {
      if (word.startsWith("@") && popupDescriptions[word]) {
        popupToken = word;
        return false;
      }
      return true;
    }).join(" ");
    
    return (
      <>
        <p className="description-text">{textWithoutPopup}</p>
        {popupToken && (
          <p className="popup-trigger" onClick={() => openPopup(popupDescriptions[popupToken])}>
            {popupToken}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="scroll-section" id="sound-examples">
      <h1 className="section-title">Podcasts</h1>
      <div className="sound-examples">
        {soundExamples.map((example, index) => (
          <div key={index} className={`sound-example ${playingIndex === index ? 'playing' : ''}`}>
            <div className="thumbnail-container">
              <img 
                src={example.image} 
                alt={example.title} 
                className={`thumbnail ${playingIndex === index ? 'playing' : ''}`}
              />
              <div className="icon" onClick={() => toggleAudio(index, example.audioPath)}>
                {playingIndex === index ? <FaPause /> : <FaPlay />}
              </div>
            </div>
            {/* Accordéon regroupant le titre et la description */}
            <div className={`accordion ${playingIndex === index ? 'expanded' : 'collapsed'}`}>
              <p className="title">{example.title}</p>
              {renderDescriptionText(example.text)}
            </div>
          </div>
        ))}
      </div>

      {playingIndex !== null && (
        <div className="mini-player">
          <div className="player-controls">
            <button onClick={() => toggleAudio(playingIndex, soundExamples[playingIndex].audioPath)}>
              {playingIndex !== null ? <FaPause /> : <FaPlay />}
            </button>
          </div>
          <div 
            className="progress-container" 
            onClick={handleSeek}
            onTouchStart={handleSeek}
            onTouchMove={handleSeek}
          >
            <span className="time">{formatTime(currentTime)}</span>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
              <div className="slider-handle" style={{ left: `${progress}%` }}></div>
            </div>
            <span className="time">{formatTime(duration)}</span>
          </div>
          <div 
            className="volume-container"
            onMouseEnter={() => clearTimeout(volumeHideTimeoutRef.current)}
            onMouseLeave={startHideVolumeTimer}
            onTouchEnd={startHideVolumeTimer}
          >
            <div className="volume-icon" onClick={toggleVolumeBar}>
              <FaVolumeUp style={{ color: '#ff9800', cursor: 'pointer' }} />
            </div>
            {showVolumeControl && (
              <div className="volume-slider visible">
                <input 
                  type="range" 
                  min="0" 
                  max="1" 
                  step="0.01" 
                  value={volume} 
                  onChange={handleVolumeChange} 
                  className="vertical-slider"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {/* Popup Modal (cliquez n'importe où dans la popup pour la fermer) */}
      {popupVisible && (
  <div className="popup-overlay" onClick={closePopup}>
    <div className="popup-content" onClick={closePopup}>
      {/* Titre de la popup */}
      <h2>{popupContent.title}</h2>
      {/* Contenu de la popup */}
      <small-texte>{popupContent.content}</small-texte>
    </div>
  </div>
      )}
    </div>
  );
};

export default SoundExample;
