import React from "react";
import { Link } from "react-router-dom"; 
import profil2 from "./image/insta.webp";  
import artiste from "./image/librairie.webp";   
import "./Home.css";  

function Home() {
  return (
    <div className="home-container">
      <Link to="/technicien" className="half-screen left">
        <div className="overlay">
          <h1>Technicien</h1>
        </div>
        <img src={profil2} alt="Technicien" />
      </Link>

      <Link to="/artistique" className="half-screen right">
        <div className="overlay">
          <h1>Artiste</h1>
        </div>
        <img src={artiste} alt="Artiste" />
      </Link>
    </div>
  );
}

export default Home;
