import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './css/presentation.css';
import './css/typography.css';

const PresentationSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { ref } = useInView({ threshold: 0.5 });
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Met à jour la hauteur du contenu lors de l'ouverture/fermeture de l'accordéon
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="scroll-section" id="presentation" ref={ref}>
<h1 className="section-title" onClick={toggleAccordion}>
  <span className={`toggle-icon ${isOpen ? 'open' : ''}`}>
    {isOpen ? "-" : "+"}
  </span>{" "}
  Présentation
</h1>
      <div
        className="accordion-content"
        ref={contentRef}
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : '0px',
          overflow: 'hidden',
          transition: 'max-height 0.4s ease-in-out',
        }}
      >
        <div className="text-column">
          <p className="scroll-text">
            Artiste sonore et ingénieur du son, je navigue avec les sons, les enregistre, les agence, tirant ainsi parti de leur plein potentiel expressif. Avoir le meilleur équipement pour mes besoins et ceux de mes clients est ma priorité afin de répondre à toutes les exigences sur le terrain, lors de tournages ou en studio.<br />
            Basé à Marseille, je me rends régulièrement à Paris pour travailler dans mon <a href="https://g.co/kgs/5Ek2ibR" target="_blank" rel="noopener noreferrer" className="zoom-link">Studio Porte Bleue</a> (5e arrondissement), où je réalise des prestations de post-production, d'enregistrement, de composition, de mixage et de mastering.<br />
            Guitariste classique et électrique de formation, je maîtrise le langage et le répertoire de la musique écrite ainsi que l’univers des musiques actuelles amplifiées, improvisées ou de studio.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PresentationSection;
