import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Importation du Link
import './css/header.css';
import './css/typography.css';

const HeaderSection = ({ scrollToSection }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="background-photo"
      style={{
        backgroundImage: "url('/image/profil2.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
      }}
    >
      <div className="fuzzy-overlay"></div>
      <img
        src="/image/profil2.webp"
        alt="Preload"
        style={{ display: 'none' }}
        loading="eager"
      />

      <div className="white-band">
        <div className="text-left">
          {/* Le titre devient un lien vers la page Home */}
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <h1 className="header-title">Valentin Leverrier</h1>
          </Link>
          {isMobile && <div className="separator" />}
          <h2>Ingénieur Son Fiction</h2>
          <h2>Assistant Son Doc</h2>
        </div>
        {/* Barre de navigation (affichée en desktop uniquement) */}
        {!isMobile && (
          <div className="sections">
            <h3 className="clickable" onClick={() => scrollToSection('presentation')}>
              Présentation
            </h3>
            <h3 className="clickable" onClick={() => scrollToSection('material')}>
              Matériel
            </h3>
            <h3 className="clickable" onClick={() => scrollToSection('experience')}>
              Expérience
            </h3>
            <h3 className="clickable" onClick={() => scrollToSection('media')}>
              Médias
            </h3>
          </div>
        )}
        {isMobile && <div className="separator" />}
        <div className="social-icons">
          <a href="mailto:valentin.leverrier@gmail.com">
            <img src="/image/mail.webp" alt="Mail" loading="lazy" />
          </a>
          <a href="tel:+33610381216">
            <img src="/image/phone.webp" alt="Téléphone" loading="lazy" />
          </a>
          <a
            href="https://www.instagram.com/fousound/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/image/insta.webp" alt="Instagram" loading="lazy" />
          </a>
        </div>
      </div>

      {/* Flèche invitant à descendre (uniquement sur smartphone) */}
      {isMobile && (
        <div
          className="scroll-arrow"
          onClick={() => scrollToSection('presentation')}
        >
          &#x2193;
        </div>
      )}
    </div>
  );
};

export default HeaderSection;
