import React, { useState, useRef } from 'react';
import './css/MediaSection.css';
import image1 from './image/media-art/1.webp';
import image2 from './image/media-art/2.webp';
import image3 from './image/media-art/3.webp';
import image4 from './image/media-art/4.webp';

const MediaSection = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [selectedLink, setSelectedLink] = useState('');
  const mediaRef = useRef(null);

  const handleImageClick = (image, text, link) => {
    setSelectedImage(image);
    setSelectedText(text);
    setSelectedLink(link);
  };

  const closePopup = () => {
    setSelectedImage(null);
    setSelectedText('');
    setSelectedLink('');
  };

  return (
    <div className="scroll-section" id="media" ref={mediaRef}>
      <h1 className="section-title" data-aos="fade-up">Gallerie</h1>
      <div className="media-gallery">
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image1)}>
          <img src={image1} alt="Media 1" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image2)}>
          <img src={image2} alt="Media 2" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image3)}>
          <img src={image3} alt="Media 3" />
        </div>
        <div className="media-item" data-aos="fade-up" onClick={() => handleImageClick(image4)}>
          <img src={image4} alt="Media 4" />
        </div>
        {/* Add other images here */}
      </div>
      {selectedImage && (
        <div className="popup media-popup" onClick={closePopup}>
          <div className="popup-content">
            <img src={selectedImage} alt="Selected" />
            <div className="popup-text">
              {selectedLink ? (
                <a
  href={selectedLink}
  target="_blank"
  rel="noopener noreferrer"
  className="custom-link"
>
  {selectedText}
</a>
              ) : (
                selectedText
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaSection;