import React from 'react';
import './css/Footer.css'; // Importez le fichier CSS pour le composant Footer

const Footer = () => {
  return (
    <div className="footer">
      <a href="https://www.instagram.com/melodioguem_/" target="_blank" rel="noopener noreferrer">
        Mélodie Gogué-Meunier au dessin
      </a>
    </div>
  );
};

export default Footer;